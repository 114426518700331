import React from 'react';
import { OptionProps } from 'react-select';

interface SelectOptionWrapperProps extends OptionProps<{}> {
  component: React.ElementType;
}

function SelectOptionWrapper(props: SelectOptionWrapperProps) {
  const {
    className = '',
    innerProps,
    isFocused,
    isSelected,
    data,
    component: Component
  } = props;

  return (
    <div className={className} {...innerProps}>
      <Component {...data} isFocused={isFocused} isSelected={isSelected} />
    </div>
  );
}

export default SelectOptionWrapper;
