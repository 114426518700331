import React from 'react';

import Typography from '../../../typography/typography';

import './page-title.scss';

interface PageTitleProps {
  className?: string;
  showDivider?: boolean;
  subtitle?: string | React.ReactNode;
  title?: (() => string) | string | React.ReactNode;
}

function PageTitle(props: React.PropsWithChildren<PageTitleProps>) {
  const {
    className = '',
    children,
    subtitle = '',
    showDivider = false,
    title = ''
  } = props;

  return (
    <div
      className={`${className || ''} page-title`}
      styleName={showDivider ? 'container-divider' : 'container'}
    >
      <div className="page-title__content">
        {title && (
          <Typography
            className="page-title__content-title"
            styleName="title"
            tag="h1"
            variant="h4"
            weight="black"
          >
            {typeof title === 'function' ? title() : title}
          </Typography>
        )}

        {subtitle && (
          <Typography
            className="page-title__content-subtitle"
            size="small"
            styleName="subtitle"
            tag="div"
          >
            {subtitle}
          </Typography>
        )}
      </div>
      {children}
    </div>
  );
}

export default PageTitle;
