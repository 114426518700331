import React, { MouseEventHandler, ReactElement, ReactNode } from 'react';

import AltButtonGroup from './components/alt-button-group/alt-button-group';
import Link from '../link/link';

import './alt-button.scss';

interface AltButtonProps {
  children: ReactNode | Array<ReactNode>;
  className?: string;
  href?: string;
  icon: React.ElementType;
  onClick?: MouseEventHandler<HTMLButtonElement>;
}

function AltButton(props: AltButtonProps) {
  const { className, icon: Icon, children, onClick, href } = props;
  let component: ReactElement;

  if (href) {
    component = (
      <Link className={className} styleName="container" to={href}>
        <>
          <Icon styleName="icon" />
          {children}
        </>
      </Link>
    );
  } else {
    component = (
      <button
        className={className}
        onClick={onClick}
        styleName="container"
        type="button"
      >
        <Icon styleName="icon" />
        {children}
      </button>
    );
  }
  return component;
}

export { AltButtonGroup };
export default AltButton;
