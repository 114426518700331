import React from 'react';
import PropTypes from 'prop-types';

import Link from '../../../../library/link/link';
import Typography from '../../../../library/typography/typography';
import PitPassLogo from '../../../../components/pitpass-logo/pitpass-logo';
import ShowHours from '../../../../components/show-hours/show-hours';
import ProductAvailabilityMessage from '../../../product-availability-message/product-availability-message';
import StoreAddress from '../../../store-address/store-address';

import { useOptimisticData } from '../../../../shared/hooks/hooks';

import { getStockCount } from '../../../../shared/utils/product/product';
import { GroupEntryStocksProp } from '../../../../shared/utils/prop-types/prop-types';

import { ENTRY_TYPES } from '../../../../shared/constants/cart';

import './store-quick-view-details.scss';

function StoreQuickViewDetails(props) {
  const {
    address,
    distance,
    //TODO - Consider refactoring this prop to only contain the stock & entryType instead of the entire groupEntry
    groupEntryStocks,
    hasRatingLink,
    hidePhone,
    hideReadReviews,
    onCallStore,
    onReadReview,
    onReadReviewClick,
    onStoreDetailsLinkClick,
    rating,
    shouldSummarizePackage,
    showPitPassLogo,
    storeCode,
    storeDetailsLink,
    storeHours
  } = props;

  const optimisticData = useOptimisticData();

  const { availabilityMessage, availabilityMessageRank, stockLevelStatus } =
    groupEntryStocks?.[0]?.stock?.[0] || {};

  const singlePackageGroupEntryStock =
    groupEntryStocks?.length === 1 &&
    groupEntryStocks[0].groupEntry?.entryType === ENTRY_TYPES.PACKAGE
      ? groupEntryStocks[0]
      : undefined;

  const singleStaggeredGroupEntryStock =
    groupEntryStocks?.length === 1 &&
    groupEntryStocks[0].groupEntry?.entryType === ENTRY_TYPES.STAGGERED
      ? groupEntryStocks[0]
      : undefined;

  function setOptimisticStoreData() {
    optimisticData.write({
      store: {
        address,
        rating
      }
    });
  }

  return (
    <div styleName="container">
      <div className="store-quick-view-details__left-col" styleName="left-col">
        <StoreAddress
          address={address}
          className="store-address"
          hasRatingLink={hasRatingLink}
          hidePhone={hidePhone}
          onCallStore={onCallStore}
          onRatingClick={() => {
            setOptimisticStoreData();
            onReadReviewClick?.();

            if (onReadReview) {
              onReadReview();
            }
          }}
          rating={rating}
          showReviews={!hideReadReviews}
          storeCode={storeCode}
        />
      </div>
      <div
        className="store-quick-view-details__right-col"
        styleName="right-col"
      >
        {typeof distance === 'number' && (
          <Typography
            className="store-quick-view-details__distance"
            styleName="store-distance"
            tag="div"
            weight="black"
          >
            {distance.toFixed(1)} mi
          </Typography>
        )}

        {showPitPassLogo && (
          <div styleName="pitpass-logo">
            <PitPassLogo />
          </div>
        )}

        {groupEntryStocks?.length === 1 && availabilityMessage && (
          <div styleName="availability">
            <ProductAvailabilityMessage
              availabilityMessage={availabilityMessage || ''}
              availabilityMessageRank={availabilityMessageRank || ''}
              stockLevelCode={stockLevelStatus?.code}
            />
          </div>
        )}
        {singlePackageGroupEntryStock && !shouldSummarizePackage && (
          <>
            <div styleName="stock-count">{`Tires: ${getStockCount(
              singlePackageGroupEntryStock.stock[0]?.stockCount
            )} in Stock`}</div>
            <div styleName="stock-count">{`Wheels: ${getStockCount(
              singlePackageGroupEntryStock.stock[1]?.stockCount
            )} in Stock`}</div>
          </>
        )}
        {singleStaggeredGroupEntryStock && (
          <>
            <Typography
              size="small"
              styleName="stock-count"
              tag="div"
              weight="heavy"
            >{`Front: ${getStockCount(
              singleStaggeredGroupEntryStock.stock[0]?.stockCount
            )} in Stock`}</Typography>
            <Typography
              size="small"
              styleName="stock-count"
              tag="div"
              weight="heavy"
            >{`Rear: ${getStockCount(
              singleStaggeredGroupEntryStock.stock[1]?.stockCount
            )} in Stock`}</Typography>
          </>
        )}
      </div>
      <div>
        {storeHours && <ShowHours storeHours={storeHours} />}
        {storeDetailsLink && (
          <div styleName="details-link">
            <Link
              onClick={() => {
                setOptimisticStoreData();
                onStoreDetailsLinkClick?.();
              }}
              to={storeDetailsLink}
            >
              Store Details
            </Link>
          </div>
        )}
      </div>
    </div>
  );
}

StoreQuickViewDetails.propTypes = {
  address: PropTypes.shape({
    line1: PropTypes.string,
    phone: PropTypes.string,
    postalCode: PropTypes.string,
    region: PropTypes.shape({
      isocodeShort: PropTypes.string
    }),
    town: PropTypes.string
  }),
  distance: PropTypes.number,
  groupEntryStocks: GroupEntryStocksProp,
  hasRatingLink: PropTypes.bool,
  hidePhone: PropTypes.bool,
  hideReadReviews: PropTypes.bool,
  onCallStore: PropTypes.func,
  onReadReview: PropTypes.func,
  onReadReviewClick: PropTypes.func,
  onStoreDetailsLinkClick: PropTypes.func,
  rating: PropTypes.shape({
    numberOfReviews: PropTypes.number,
    rating: PropTypes.number
  }),
  shouldSummarizePackage: PropTypes.bool,
  showPitPassLogo: PropTypes.bool,
  storeCode: PropTypes.string,
  storeDetailsLink: PropTypes.string,
  storeHours: PropTypes.shape({
    monFriHours: PropTypes.string,
    satHours: PropTypes.string,
    sunHours: PropTypes.string
  })
};

export default StoreQuickViewDetails;
