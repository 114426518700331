import React from 'react';

import { DotCircleRegularIcon } from '../../../shared/icons/icons';

import './my-location-marker.scss';

function MyLocationMarker() {
  return <DotCircleRegularIcon styleName="icon" />;
}

export default React.memo(MyLocationMarker);
