import React, { useEffect, useRef, useState } from 'react';

import CollapsibleToggle from './components/collapsible-toggle/collapsible-toggle';
import Typography from '../typography/typography';
import { collapseType, toggleType } from './collapsible-types';

import './collapsible.scss';

interface CollapsibleProps {
  className?: string;
  collapseType?: collapseType;
  customLabel?: string | null;
  description?: string;
  hideToggle?: boolean;
  id?: string;
  isDisabled?: boolean;
  isOpen?: boolean;
  isOpenByDefault?: boolean;
  label?: string | JSX.Element;
  onToggle?: (isOpen?: boolean) => void;
  shouldExpandUp?: boolean;
  toggleType?: toggleType;
}

function Collapsible(props: React.PropsWithChildren<CollapsibleProps>) {
  const {
    children,
    className = '',
    collapseType,
    description,
    hideToggle,
    id,
    isDisabled = false,
    isOpen: isOpenProp,
    isOpenByDefault = false,
    label,
    onToggle,
    customLabel,
    shouldExpandUp = false,
    toggleType
  } = props;
  const [isOpenInternal, setIsOpenInternal] = useState(isOpenByDefault);
  const [contentHeight, setContentHeight] = useState<number | string>(0);
  const openTimerRef = useRef<ReturnType<typeof setTimeout> | undefined>(
    undefined
  );
  const contentRef = useRef<HTMLDivElement | null>(null);
  const expandTime = 340;
  const isOpen = typeof isOpenProp === 'boolean' ? isOpenProp : isOpenInternal;

  function handleOpenClose(isOpen: boolean) {
    const contentHeight = contentRef?.current?.clientHeight;

    if (isOpen) {
      if (contentHeight) {
        setContentHeight(contentHeight);
      }

      openTimerRef.current = setTimeout(() => {
        setContentHeight('auto');
      }, expandTime);
    } else {
      if (openTimerRef.current) {
        clearTimeout(openTimerRef.current);
      }
      if (contentHeight) {
        setContentHeight(contentHeight);

        setTimeout(() => {
          setContentHeight(0);
        }, 20);
      }
    }
  }

  function toggleCollapsible() {
    const hasIsOpenProp = typeof isOpenProp === 'boolean';

    if (typeof onToggle === 'function') {
      if (hasIsOpenProp) {
        onToggle(!isOpenProp);
      } else {
        onToggle(!isOpenInternal);
      }
    }

    if (!hasIsOpenProp) {
      setIsOpenInternal(!isOpenInternal);
    }
  }

  useEffect(() => {
    if (typeof isOpenProp !== 'boolean') {
      handleOpenClose(isOpenInternal);
    }
  }, [isOpenInternal, isOpenProp]);

  useEffect(() => {
    if (typeof isOpenProp === 'boolean') {
      handleOpenClose(isOpenProp);
    }
  }, [isOpenProp]);

  return (
    <div className={`collapsible ${className}`} id={id} styleName="container">
      {!shouldExpandUp && !hideToggle && (
        <div className="collapsible__label">
          <CollapsibleToggle
            className={className}
            collapseType={collapseType}
            isDisabled={isDisabled}
            isOpen={isOpen}
            label={label}
            toggleCollapsible={toggleCollapsible}
            toggleType={toggleType}
          />
          {customLabel && (
            <Typography className="collapsible__custom-label">
              {customLabel}
            </Typography>
          )}
        </div>
      )}
      <div
        className="collapsible__content-wrapper"
        style={{
          height: contentHeight
        }}
        styleName="content-wrapper"
      >
        <div
          className="collapsible__content"
          ref={contentRef}
          styleName="content"
        >
          {description && (
            <Typography
              className="collapsible__description"
              styleName="description"
            >
              {description}
            </Typography>
          )}
          {children}
        </div>
      </div>
      {shouldExpandUp && !hideToggle && (
        <CollapsibleToggle
          className={className}
          collapseType={collapseType}
          isDisabled={isDisabled}
          isOpen={isOpen}
          label={label}
          toggleCollapsible={toggleCollapsible}
        />
      )}
    </div>
  );
}

export default Collapsible;
