import React from 'react';

import {
  AngleDownLightIcon,
  AngleUpLightIcon
} from '../../../../shared/icons/icons';

interface SelectToggleProps {
  selectProps: {
    menuIsOpen: boolean;
  };
}

function SelectToggle(props: SelectToggleProps) {
  const {
    selectProps: { menuIsOpen: isOpen }
  } = props;

  return isOpen ? (
    <AngleUpLightIcon className="select-toggle" />
  ) : (
    <AngleDownLightIcon className="select-toggle" />
  );
}

export default SelectToggle;
