import React from 'react';
import PropTypes from 'prop-types';

import AltButton from '../../../../library/alt-button/alt-button';
import Button from '../../../../library/button/button';
import AltButtonGroup from '../../../../library/alt-button/components/alt-button-group/alt-button-group';
import ButtonGroup from '../../../../library/button/components/button-group/button-group';
import {
  CalendarAltSolidIcon,
  ExchangeAltSolidIcon,
  LocationArrowSolidIcon,
  ShareAltSolidIcon
} from '../../../../shared/icons/icons';
import ShorterWaitTimeMessage from '../../../shorter-wait-time-message/shorter-wait-time-message';

import {
  useFitment,
  useModal,
  useMyStore,
  useScheduleAppointment
} from '../../../../shared/hooks/hooks';

import { getGoogleMapsLink } from '../../../../shared/utils/store/store';

import { SHARE_STORE } from '../../../../shared/constants/modals';

function StoreQuickViewCtas(props) {
  const {
    address,
    cartReconciliationWarningMessage,
    distance,
    hideShorterWaitTimeMessage = false,
    isMyStore = false,
    isStoreSelectionDisabled,
    legacyStoreCode,
    onSelectStore,
    onChangeStore,
    productItem,
    rating,
    selectStoreButtonLabel = 'MAKE THIS MY STORE',
    shouldSetStoreAndCart = true,
    showAltChangeStoreCTA,
    showAltDirectionsCTA,
    showAltScheduleAppointmentCTA,
    showAltShareStoreCTA,
    showAppointmentCTA,
    showContinueCTA,
    showMakeMyStoreCTA,
    showShopProductsCTA,
    storeCode,
    onGetDirectionsClick,
    onSetStoreClick,
    onScheduleAppointmentClick,
    onShopProductsClick,
    onShareClick,
    onContinueClick
  } = props;

  const {
    town,
    region: { isocodeShort: regionCode } = {},
    postalCode,
    line1
  } = address || {};
  const { setStore } = useMyStore();
  const { handleScheduleAppointment } = useScheduleAppointment();
  const { selectedFitment } = useFitment();
  const { openModal } = useModal();

  async function setStoreAndCart() {
    onSelectStore?.(address, storeCode);

    let setStoreResult;
    if (shouldSetStoreAndCart) {
      try {
        setStoreResult = await setStore(storeCode, {
          cartReconciliationWarningMessage,
          fitment: selectedFitment,
          productItem,
          shouldConfirmStore: true,
          storeAddress: address
        });
      } catch (e) {
        console.log('Error setting store: ', e);
      }

      onChangeStore?.(false);
    }

    onSetStoreClick?.(setStoreResult);
  }

  function onDirectionsClick() {
    onGetDirectionsClick?.();

    if (town && regionCode && postalCode) {
      return window.open(
        getGoogleMapsLink(town, regionCode, postalCode, line1),
        '_blank'
      );
    }
  }

  const showAltCtas =
    showAltDirectionsCTA ||
    showAltScheduleAppointmentCTA ||
    showAltShareStoreCTA ||
    showAltChangeStoreCTA;
  const showCtas =
    showAppointmentCTA ||
    showMakeMyStoreCTA ||
    showShopProductsCTA ||
    showContinueCTA;

  return (
    <>
      {showAltCtas && (
        <AltButtonGroup>
          {showAltDirectionsCTA && (
            <AltButton
              icon={LocationArrowSolidIcon}
              onClick={onDirectionsClick}
            >
              Directions
            </AltButton>
          )}

          {showAltScheduleAppointmentCTA && (
            <AltButton
              icon={CalendarAltSolidIcon}
              onClick={() => {
                handleScheduleAppointment(storeCode);
                onScheduleAppointmentClick?.();
              }}
            >
              Appointment
            </AltButton>
          )}

          {showAltShareStoreCTA && (
            <AltButton
              icon={ShareAltSolidIcon}
              onClick={() => {
                onShareClick?.();
                openModal(SHARE_STORE, {
                  store: {
                    address,
                    code: storeCode,
                    distance,
                    legacyStoreCode,
                    rating
                  }
                });
              }}
            >
              Share
            </AltButton>
          )}

          {showAltChangeStoreCTA && (
            <AltButton
              icon={ExchangeAltSolidIcon}
              onClick={() => onChangeStore(true)}
            >
              Change Store
            </AltButton>
          )}
        </AltButtonGroup>
      )}

      {showCtas && (
        <>
          {isMyStore && !hideShorterWaitTimeMessage && (
            <ShorterWaitTimeMessage />
          )}
          <ButtonGroup layout="vertical">
            {showShopProductsCTA && (
              <Button
                href={
                  selectedFitment
                    ? '#/fitment/vehicle/results'
                    : '#/fitment/vehicle'
                }
                isPrimary
                onClick={onShopProductsClick}
              >
                Shop Products
              </Button>
            )}
            {showContinueCTA && (
              <Button
                isPrimary
                onClick={() => {
                  onContinueClick();
                  onChangeStore?.(false);
                }}
              >
                {' '}
                Continue{' '}
              </Button>
            )}
            {showMakeMyStoreCTA && (
              <Button
                disabled={isStoreSelectionDisabled}
                isSecondary
                onClick={setStoreAndCart}
                type="submit"
              >
                {selectStoreButtonLabel}
              </Button>
            )}
            {showAppointmentCTA && (
              <Button
                isSecondary
                onClick={() => {
                  handleScheduleAppointment(storeCode);
                  onScheduleAppointmentClick?.();
                }}
              >
                Schedule Appointment
              </Button>
            )}
          </ButtonGroup>
        </>
      )}
    </>
  );
}

StoreQuickViewCtas.propTypes = {
  address: PropTypes.shape({
    line1: PropTypes.string,
    postalCode: PropTypes.string,
    region: PropTypes.shape({
      isocodeShort: PropTypes.string
    }),
    town: PropTypes.string
  }),
  cartReconciliationWarningMessage: PropTypes.string,
  distance: PropTypes.number,
  hideShorterWaitTimeMessage: PropTypes.bool,
  isMyStore: PropTypes.bool,
  isStoreSelectionDisabled: PropTypes.bool,
  legacyStoreCode: PropTypes.string,
  onChangeStore: PropTypes.func,
  onContinueClick: PropTypes.func,
  onGetDirectionsClick: PropTypes.func,
  onScheduleAppointmentClick: PropTypes.func,
  onSelectStore: PropTypes.func,
  onSetStoreClick: PropTypes.func,
  onShareClick: PropTypes.func,
  onShopProductsClick: PropTypes.func,
  productItem: PropTypes.object,
  rating: PropTypes.shape({
    rating: PropTypes.number
  }),
  selectStoreButtonLabel: PropTypes.string,
  shouldSetStoreAndCart: PropTypes.bool,
  showAltChangeStoreCTA: PropTypes.bool,
  showAltDirectionsCTA: PropTypes.bool,
  showAltScheduleAppointmentCTA: PropTypes.bool,
  showAltShareStoreCTA: PropTypes.bool,
  showAppointmentCTA: PropTypes.bool,
  showContinueCTA: PropTypes.bool,
  showMakeMyStoreCTA: PropTypes.bool,
  showShopProductsCTA: PropTypes.bool,
  storeCode: PropTypes.string
};

export default StoreQuickViewCtas;
