import React from 'react';

import {
  ChevronDownRegularIcon,
  ChevronUpRegularIcon,
  MinusRegularIcon,
  PlusRegularIcon
} from '../../../../shared/icons/icons';
import PlusSolidIcon from '../../../../shared/icons/plus-solid-icon';
import TimesSolidIcon from '../../../../shared/icons/times-solid-icon';
import Button from '../../../button/button';
import Typography from '../../../typography/typography';
import { collapseType, toggleType } from '../../collapsible-types';

import './collapsible-toggle.scss';

interface CollapsibleToggleProps {
  className?: string;
  collapseType?: collapseType;
  isDisabled?: boolean;
  isOpen?: boolean;
  label?: string | JSX.Element;
  toggleCollapsible?: () => void;
  toggleType?: toggleType;
}

function CollapsibleToggle(props: CollapsibleToggleProps) {
  const {
    className = '',
    collapseType = 'PRIMARY',
    isDisabled = false,
    isOpen,
    label,
    toggleCollapsible,
    toggleType = ''
  } = props;

  let Icon;

  if (toggleType === 'CHEVRON') {
    Icon = isOpen ? ChevronUpRegularIcon : ChevronDownRegularIcon;
  } else if (toggleType === 'TIMES') {
    Icon = isOpen ? TimesSolidIcon : PlusSolidIcon;
  } else {
    Icon = isOpen ? MinusRegularIcon : PlusRegularIcon;
  }

  const isPrimary = collapseType === 'PRIMARY';
  const statusClassName = isOpen
    ? 'collapsible-toggle--open'
    : 'collapsible-toggle--closed';

  return (
    <Button
      className={`collapsible-toggle ${className} ${statusClassName}`}
      isDisabled={isDisabled}
      onClick={toggleCollapsible}
      styleName={`${isPrimary ? 'primary-' : ''}container`}
      type="button"
    >
      {!isPrimary && (
        <Icon
          className="collapsible-toggle__icon"
          styleName={`${
            toggleType === 'CHEVRON' ? 'chevron-icon' : 'icon-left'
          }`}
        />
      )}
      <Typography
        className="collapsible-toggle__label"
        tag="span"
        weight={isPrimary ? 'black' : undefined}
      >
        {label}
      </Typography>

      {isPrimary && (
        <Icon
          className="collapsible-toggle__icon"
          styleName={`${
            toggleType === 'CHEVRON' ? 'chevron-icon' : 'icon-right'
          }`}
        />
      )}
    </Button>
  );
}

export default CollapsibleToggle;
