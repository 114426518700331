import React from 'react';

import Toggle from '../../../../library/toggle/toggle';
import PitPassLogo from '../../../pitpass-logo/pitpass-logo';

import './pitpass-filter.scss';

interface PitpassFilterProps {
  isEnabled: boolean;
  onToggle: () => void;
}

function PitpassFilter(props: PitpassFilterProps): React.ReactElement {
  const { isEnabled, onToggle } = props;
  const id = 'pitpassStoresOnly';

  return (
    <div styleName="container">
      <PitPassLogo />

      <p>
        Our Pit Pass stores offer speedy drive-through fittings when you’ve
        purchased your tires and scheduled an appointment online.
      </p>

      <div styleName="action">
        <Toggle ariaLabelledBy={id} isEnabled={isEnabled} onToggle={onToggle} />
      </div>

      <span id={id} styleName="action-label">
        Only show Pit Pass stores
      </span>
    </div>
  );
}

export default PitpassFilter;
