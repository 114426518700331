import React from 'react';
import PropTypes from 'prop-types';

import Tooltip from '../../../../library/tooltip/tooltip';
import CmsIcon from '../../../../shared/icons/cms-icon';

import { isArrayWithLength } from '../../../../shared/utils/validators/validators';

import './store-quick-view-features.scss';

function StoreQuickViewFeatures(props) {
  const { features } = props;

  if (!isArrayWithLength(features)) {
    return null;
  }

  return (
    <div styleName="container">
      {features.map((feature, idx) => {
        const icon = <CmsIcon name={feature?.iconName?.toLowerCase()} />;

        if (!icon) {
          return null;
        }

        return (
          <Tooltip
            ariaLabel={feature?.name || feature?.code}
            content={feature?.description}
            key={`fi-${idx}`}
            render={icon}
            styleName="icon"
          />
        );
      })}
    </div>
  );
}

StoreQuickViewFeatures.propTypes = {
  features: PropTypes.arrayOf(
    PropTypes.shape({
      code: PropTypes.string,
      description: PropTypes.string,
      iconName: PropTypes.string,
      name: PropTypes.string
    })
  )
};
export default StoreQuickViewFeatures;
