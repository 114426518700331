import React, { useState } from 'react';

import Collapsible from '../../../../library/collapsible/collapsible';
import Typography from '../../../../library/typography/typography';
import ProductAvailabilityStaggeredEntry from '../product-availability-staggered-entry/product-availability-staggered-entry';

import {
  getStaggeredSetFromPackage,
  isStaggeredPackageGroupEntry
} from '../../../../shared/utils/cart/cart';
import {
  getPackageStockCount,
  getStockCount
} from '../../../../shared/utils/product/product';
import { isArrayWithLength } from '../../../../shared/utils/validators/validators';

import { GroupEntryStocks } from '../../../../shared/types/product';
import { ENTRY_TYPES } from '../../../../shared/constants/cart';

import './product-availability-collapsible.scss';

interface ProductAvailabilityCollapsibleProps {
  groupEntryStocks?: GroupEntryStocks[];
}

function ProductAvailabilityCollapsible(
  props: ProductAvailabilityCollapsibleProps
) {
  const { groupEntryStocks } = props;
  const [isOpen, setIsOpen] = useState(false);

  if (!isArrayWithLength(groupEntryStocks)) {
    return null;
  }
  return (
    <Collapsible
      isOpen={isOpen}
      label="Product Availability"
      onToggle={() => setIsOpen(!isOpen)}
      styleName="container"
    >
      {groupEntryStocks?.map(({ groupEntry, stock }, i) => {
        let firstProduct = groupEntry?.entries?.[0]?.product;
        let secondProduct = groupEntry?.entries?.[1]?.product;
        const isStaggeredPackage = isStaggeredPackageGroupEntry(groupEntry);
        if (isStaggeredPackage) {
          const firstSet = getStaggeredSetFromPackage(groupEntry, 0);
          const secondSet = getStaggeredSetFromPackage(groupEntry, 1);
          firstProduct = firstSet[0]?.product;
          secondProduct = secondSet[0]?.product;
        }
        const { brand: firstProductBrand, name: firstProductName } =
          firstProduct || {};
        const { brand: secondProductBrand, name: secondProductName } =
          secondProduct || {};
        const firstProductBrandAndName = `${
          firstProductBrand || ''
        } ${firstProductName}`;
        const secondProductBrandAndName = `${
          secondProductBrand || ''
        } ${secondProductName}`;
        const isPrebuilt = groupEntry?.groupId?.includes('pre');
        const [
          firstProductStock,
          secondProductStock,
          thirdProductStock,
          fourthProductStock
        ] = stock || [];

        return (
          <Typography key={i} size="small" tag="div">
            {groupEntry?.entryType === ENTRY_TYPES.STANDARD && (
              <div key={`${i}-${firstProduct?.code}`} styleName="standard">
                <div>{firstProductBrandAndName}</div>
                <Typography
                  size="small"
                  styleName="stock"
                  tag="div"
                  weight="black"
                >
                  {getStockCount(firstProductStock?.stockCount)} in Stock
                </Typography>
              </div>
            )}
            {groupEntry?.entryType === ENTRY_TYPES.PACKAGE && (
              <div key={`${i}-${groupEntry?.groupId}`} styleName="package">
                <div styleName="package-container">
                  <Typography
                    size="small"
                    styleName="package-title"
                    tag="div"
                    weight="heavy"
                  >
                    Tire & Wheel Package:
                  </Typography>
                  {isPrebuilt && (
                    <Typography
                      size="small"
                      styleName="stock"
                      tag="div"
                      weight="black"
                    >
                      {getPackageStockCount(
                        firstProductStock?.stockCount,
                        secondProductStock?.stockCount
                      )}{' '}
                      in Stock
                    </Typography>
                  )}
                </div>

                <div styleName="package-container">
                  <div styleName="package-entry-name">
                    {firstProductBrandAndName}
                  </div>
                  {!isPrebuilt && !isStaggeredPackage && (
                    <Typography
                      size="small"
                      styleName="stock"
                      tag="div"
                      weight="black"
                    >
                      {getStockCount(firstProductStock?.stockCount)} in Stock
                    </Typography>
                  )}
                  {isStaggeredPackage && (
                    <div>
                      <ProductAvailabilityStaggeredEntry
                        label="Front"
                        stockCount={firstProductStock?.stockCount}
                      />
                      <ProductAvailabilityStaggeredEntry
                        label="Rear"
                        stockCount={secondProductStock?.stockCount}
                      />
                    </div>
                  )}
                </div>
                <div styleName="package-container">
                  <div styleName="package-entry-name">
                    {secondProductBrandAndName}
                  </div>
                  {!isPrebuilt && !isStaggeredPackage && (
                    <Typography
                      size="small"
                      styleName="stock"
                      tag="div"
                      weight="black"
                    >
                      {getStockCount(secondProductStock?.stockCount)} in Stock
                    </Typography>
                  )}
                  {isStaggeredPackage && (
                    <div>
                      <ProductAvailabilityStaggeredEntry
                        label="Front"
                        stockCount={thirdProductStock?.stockCount}
                      />
                      <ProductAvailabilityStaggeredEntry
                        label="Rear"
                        stockCount={fourthProductStock?.stockCount}
                      />
                    </div>
                  )}
                </div>
              </div>
            )}
            {groupEntry?.entryType === ENTRY_TYPES.STAGGERED && (
              <div key={`${i}-${groupEntry?.groupId}`} styleName="staggered">
                <div styleName="staggered-name">{firstProductBrandAndName}</div>
                <div>
                  <ProductAvailabilityStaggeredEntry
                    label="Front"
                    stockCount={firstProductStock?.stockCount}
                  />
                  <ProductAvailabilityStaggeredEntry
                    label="Rear"
                    stockCount={secondProductStock?.stockCount}
                  />
                </div>
              </div>
            )}
          </Typography>
        );
      })}
    </Collapsible>
  );
}

export default ProductAvailabilityCollapsible;
