import React, { lazy, Suspense } from 'react';

const icons = {
  'angle-double-left-solid': lazy(
    () => import('./angle-double-left-solid-icon')
  ),
  'angle-double-right-solid': lazy(
    () => import('./angle-double-right-solid-icon')
  ),
  'angle-down-light': lazy(() => import('./angle-down-light-icon')),
  'angle-left-light': lazy(() => import('./angle-left-light-icon')),
  'angle-left-solid': lazy(() => import('./angle-left-solid-icon')),
  'angle-right-light': lazy(() => import('./angle-right-light-icon')),
  'angle-right-solid': lazy(() => import('./angle-right-solid-icon')),
  'angle-up-light': lazy(() => import('./angle-up-light-icon')),
  'bars-solid': lazy(() => import('./bars-solid-icon')),
  'bookmark-solid': lazy(() => import('./bookmark-solid-icon')),
  'calendar-alt-regular': lazy(() => import('./calendar-alt-regular-icon')),
  'calendar-alt-solid': lazy(() => import('./calendar-alt-solid-icon')),
  'calendar-times-regular': lazy(() => import('./calendar-times-regular-icon')),
  'car-alt-solid': lazy(() => import('./car-alt-solid-icon')),
  'car-light': lazy(() => import('./car-light-icon')),
  'cars-solid': lazy(() => import('./cars-solid-icon')),
  'chart-bar-solid': lazy(() => import('./chart-bar-solid-icon')),
  'check-circle-solid': lazy(() => import('./check-circle-solid-icon')),
  'check-solid': lazy(() => import('./check-solid-icon')),
  'chevron-down-regular': lazy(() => import('./chevron-down-regular-icon')),
  'chevron-right-solid': lazy(() => import('./chevron-right-solid-icon')),
  'chevron-up-regular': lazy(() => import('./chevron-up-regular-icon')),
  'circle-solid': lazy(() => import('./circle-solid-icon')),
  'clock-light': lazy(() => import('./clock-light-icon')),
  'clock-regular': lazy(() => import('./clock-regular-icon')),
  'cloud-showers-heavy-regular': lazy(
    () => import('./cloud-showers-heavy-regular-icon')
  ),
  'cloud-sun-rain-solid': lazy(() => import('./cloud-sun-rain-solid-icon')),
  'comment-dots-solid': lazy(() => import('./comment-dots-solid-icon')),
  'credit-card-regular': lazy(() => import('./credit-card-regular-icon')),
  'dot-circle-regular': lazy(() => import('./dot-circle-regular-icon')),
  'edit-light': lazy(() => import('./edit-light-icon')),
  'edit-regular': lazy(() => import('./edit-regular-icon')),
  'envelope-solid': lazy(() => import('./envelope-solid-icon')),
  'exchange-alt-solid': lazy(() => import('./exchange-alt-solid-icon')),
  'exclamation-triangle-solid': lazy(
    () => import('./exclamation-triangle-solid-icon')
  ),
  'external-link-alt-solid': lazy(
    () => import('./external-link-alt-solid-icon')
  ),
  'eye-slash-solid': lazy(() => import('./eye-slash-solid-icon')),
  'eye-solid': lazy(() => import('./eye-solid-icon')),
  'facebook-f': lazy(() => import('./facebook-f-icon')),
  'file-certificate-solid': lazy(() => import('./file-certificate-solid-icon')),
  'file-invoice-dollar-regular': lazy(
    () => import('./file-invoice-dollar-regular-icon')
  ),
  'flag-checkered-solid': lazy(() => import('./flag-checkered-solid-icon')),
  'folder-open-regular': lazy(() => import('./folder-open-regular-icon')),
  'folder-open-solid': lazy(() => import('./folder-open-solid-icon')),
  'google-plus-g': lazy(() => import('./google-plus-g-icon')),
  'handshake-regular': lazy(() => import('./handshake-regular-icon')),
  'history-regular': lazy(() => import('./history-regular-icon')),
  'id-card-light': lazy(() => import('./id-card-light-icon')),
  'id-card-regular': lazy(() => import('./id-card-regular-icon')),
  'info-circle-light': lazy(() => import('./info-circle-light-icon')),
  'info-circle-regular': lazy(() => import('./info-circle-regular-icon')),
  'info-circle-solid': lazy(() => import('./info-circle-solid-icon')),
  instagram: lazy(() => import('./instagram-icon')),
  'location-arrow-solid': lazy(() => import('./location-arrow-solid-icon')),
  'lock-solid': lazy(() => import('./lock-solid-icon')),
  'map-marker-alt-solid': lazy(() => import('./map-marker-alt-solid-icon')),
  'minus-light': lazy(() => import('./minus-light-icon')),
  'mountains-solid': lazy(() => import('./mountains-solid-icon')),
  'phone-solid': lazy(() => import('./phone-solid-icon')),
  'pinterest-p': lazy(() => import('./pinterest-p-icon')),
  'plus-circle-light': lazy(() => import('./plus-circle-light-icon')),
  'plus-light': lazy(() => import('./plus-light-icon')),
  'print-solid': lazy(() => import('./print-solid-icon')),
  'road-solid': lazy(() => import('./road-solid-icon')),
  'search-minus-solid': lazy(() => import('./search-minus-solid-icon')),
  'search-plus-solid': lazy(() => import('./search-plus-solid-icon')),
  'search-solid': lazy(() => import('./search-solid-icon')),
  'share-alt-solid': lazy(() => import('./share-alt-solid-icon')),
  'shopping-cart-solid': lazy(() => import('./shopping-cart-solid-icon')),
  'snowflake-solid': lazy(() => import('./snowflake-solid-icon')),
  'spinner-solid': lazy(() => import('./spinner-solid-icon')),
  'star-half-alt-solid': lazy(() => import('./star-half-alt-solid-icon')),
  'star-half-solid': lazy(() => import('./star-half-solid-icon')),
  'star-solid': lazy(() => import('./star-solid-icon')),
  'sun-solid': lazy(() => import('./sun-solid-icon')),
  'tags-solid': lazy(() => import('./tags-solid-icon')),
  'thumbs-down-solid': lazy(() => import('./thumbs-down-solid-icon')),
  'thumbs-up-solid': lazy(() => import('./thumbs-up-solid-icon')),
  'times-circle-regular': lazy(() => import('./times-circle-regular-icon')),
  'times-circle-solid': lazy(() => import('./times-circle-solid-icon')),
  'times-light': lazy(() => import('./times-light-icon')),
  'times-solid': lazy(() => import('./times-solid-icon')),
  'tire-light': lazy(() => import('./tire-light-icon')),
  'tire-pressure-warning-solid': lazy(
    () => import('./tire-pressure-warning-solid-icon')
  ),
  'tire-solid': lazy(() => import('./tire-solid-icon')),
  'traffic-light-regular': lazy(() => import('./traffic-light-regular-icon')),
  'trash-alt-light': lazy(() => import('./trash-alt-light-icon')),
  'trash-alt-regular': lazy(() => import('./trash-alt-regular-icon')),
  'trophy-solid': lazy(() => import('./trophy-solid-icon')),
  twitter: lazy(() => import('./twitter-icon')),
  'undo-regular': lazy(() => import('./undo-regular-icon')),
  'user-circle-light': lazy(() => import('./user-circle-light-icon')),
  'user-circle-solid': lazy(() => import('./user-circle-solid-icon')),
  'user-cog-solid': lazy(() => import('./user-cog-solid-icon')),
  'user-solid': lazy(() => import('./user-solid-icon')),
  'warehouse-light': lazy(() => import('./warehouse-light-icon')),
  'wifi-solid': lazy(() => import('./wifi-solid-icon')),
  'wrench-solid': lazy(() => import('./wrench-solid-icon')),
  youtube: lazy(() => import('./youtube-icon'))
} as const;

type IconName = keyof typeof icons;

interface CmsIconProps {
  name: IconName;
}

function toIconName(proposed) {
  if (proposed in icons) {
    return proposed;
  } else if (`${proposed}-regular` in icons) {
    return `${proposed}-regular`;
  } else if (`${proposed}-solid` in icons) {
    return `${proposed}-solid`;
  } else if (`${proposed}-light` in icons) {
    return `${proposed}-light`;
  } else {
    return null;
  }
}

function CmsIcon(props: CmsIconProps) {
  const { name: proposed, ...otherProps } = props;
  const iconName = toIconName(proposed);

  if (!iconName) {
    return null;
  }

  const Icon = icons[iconName];

  return (
    <Suspense fallback={<span />}>
      <Icon {...otherProps} />
    </Suspense>
  );
}

export default CmsIcon;
