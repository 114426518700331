import React from 'react';

import Typography from '../../../../library/typography/typography';

import { getStockCount } from '../../../../shared/utils/product/product';

import { Maybe } from '../../../../shared/types/graphql-types';

import './product-availability-staggered-entry.scss';

interface ProductAvailabilityStaggeredEntryProps {
  label: string;
  stockCount: Maybe<number> | undefined;
}

function ProductAvailabilityStaggeredEntry(
  props: ProductAvailabilityStaggeredEntryProps
) {
  const { label, stockCount } = props;
  return (
    <div styleName="staggered-entry">
      <div styleName="axle-type">{label}: </div>
      <Typography size="small" styleName="stock" tag="div" weight="black">
        {getStockCount(stockCount)} in Stock
      </Typography>
    </div>
  );
}

export default ProductAvailabilityStaggeredEntry;
