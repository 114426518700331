import React from 'react';
import PropTypes from 'prop-types';
import queryString from 'query-string';

import Button from '../../library/button/button';
import Image from '../../library/image/image';

import { getAffiliateMarketingParams } from '../../shared/utils/url-params/url-params';

import './store-locator-message.scss';

function StoreLocatorMessage(props) {
  const { isValidSearch, latestSearch } = props;
  const affiliateMarketingUrlParams = queryString.stringify(
    getAffiliateMarketingParams()
  );

  let urlParams = affiliateMarketingUrlParams
    ? `?${affiliateMarketingUrlParams}`
    : '';

  return (
    <div styleName="message-container">
      <Image
        alt={`${
          process.env.__FF_REPLACE_DTD_WITH_TIRE_RACK__ === 'true'
            ? 'Tire Rack'
            : 'Discount Tire Direct'
        } Logo`}
        loading="lazy"
        src={
          process.env.__FF_REPLACE_DTD_WITH_TIRE_RACK__ === 'true'
            ? 'https://www.tirerack.com/content/dam/tirerack/desktop/Navigation/TireRackLogo.png'
            : `${process.env.__CDN_ORIGIN__}/sys-master/images/h93/h31/8805357125662/DTD_logo.png`
        }
        styleName="logo"
      />
      <div styleName="content">
        <>
          {isValidSearch ? (
            <p>
              The nearest store to &quot;{latestSearch}&quot; is more than
              &nbsp;
              <strong>250 miles</strong> away.
            </p>
          ) : (
            <p>There are no stores to show based on your query.</p>
          )}
          <p>
            {process.env.__FF_REPLACE_DTD_WITH_TIRE_RACK__ === 'true'
              ? `Head to Tire Rack to shop for tires, wheels, and accessories online
              with local installation options across the nation.`
              : `Purchase tires and wheels online, and ship to your door. Free
              shipping options are available.`}
          </p>
        </>
      </div>
      <Button
        href={`${
          process.env.__FF_REPLACE_DTD_WITH_TIRE_RACK__ === 'true'
            ? process.env.__TIRE_RACK_HOST__
            : process.env.__DTD_HOST__
        }${urlParams}`}
        isSecondary
        styleName="shop-button"
      >
        Shop{' '}
        {process.env.__FF_REPLACE_DTD_WITH_TIRE_RACK__ === 'true'
          ? 'Tire Rack'
          : 'Discount Tire Direct'}
      </Button>
    </div>
  );
}

StoreLocatorMessage.propTypes = {
  isValidSearch: PropTypes.bool,
  latestSearch: PropTypes.string
};

export default StoreLocatorMessage;
