import React from 'react';
import { isNil } from 'lodash';
import PropTypes from 'prop-types';

import './pagination-message.scss';

function PaginationMessage(props) {
  const { className, maxResultsPerPage, pageIndex, totalNumberOfResults } =
    props;

  let message = `${totalNumberOfResults === 1 ? 'store' : 'stores'} found`;

  if (totalNumberOfResults > 10) {
    const resultStart = pageIndex * maxResultsPerPage + 1;
    const resultEnd =
      resultStart + (maxResultsPerPage - 1) > totalNumberOfResults
        ? totalNumberOfResults
        : resultStart + (maxResultsPerPage - 1);

    message = `${resultStart}-${resultEnd} of ${totalNumberOfResults} ${message}`;
  } else if (totalNumberOfResults <= 10) {
    message = `${totalNumberOfResults} ${message}`;
  }

  let showPaginationMessage = true;
  if (
    isNil(pageIndex) ||
    isNil(maxResultsPerPage) ||
    isNil(totalNumberOfResults)
  ) {
    showPaginationMessage = false;
  }

  return (
    <>
      {showPaginationMessage && (
        <div className={className} styleName="container">
          <span styleName="message">{message}</span>
        </div>
      )}
    </>
  );
}

PaginationMessage.propTypes = {
  className: PropTypes.string,
  maxResultsPerPage: PropTypes.number.isRequired,
  pageIndex: PropTypes.number.isRequired,
  totalNumberOfResults: PropTypes.number.isRequired
};

export default PaginationMessage;
