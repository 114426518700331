import React from 'react';
import PropTypes from 'prop-types';

import ProductAvailabilityCollapsible from './components/product-availability-collapsible/product-availability-collapsible';
import StoreQuickViewCtas from './components/store-quick-view-ctas/store-quick-view-ctas';
import StoreQuickViewDetails from './components/store-quick-view-details/store-quick-view-details';
import StoreQuickViewFeatures from './components/store-quick-view-features/store-quick-view-features';
import StoreQuickViewHeader from './components/store-quick-view-header/store-quick-view-header';
import { MapMarkerAltSolidIcon } from '../../shared/icons/icons';

import { GroupEntryStocksProp } from '../../shared/utils/prop-types/prop-types';

import './store-quick-view.scss';

function StoreQuickView(props) {
  const {
    cartReconciliationWarningMessage,
    className,
    address,
    distance,
    features,
    groupEntryStocks,
    hasRatingLink,
    hidePhone,
    hideReadReviews,
    hideHeader,
    hideShorterWaitTimeMessage,
    isMyStore,
    isStoreSelectionDisabled,
    legacyStoreCode,
    rating,
    onReadReview,
    onSelectStore,
    onChangeStore,
    isPitPassStore,
    selectStoreButtonLabel,
    shouldSetStoreAndCart,
    shouldSummarizePackage,
    showAltChangeStoreCTA,
    showAltDirectionsCTA,
    showAltScheduleAppointmentCTA,
    showAltShareStoreCTA,
    showAppointmentCTA,
    showContinueCTA,
    showDistance,
    showMakeMyStoreCTA,
    showShopProductsCTA,
    storeCode,
    storeDetailsLink,
    storeHours,
    myStoreTitle,
    onCallStore,
    onReadReviewClick,
    onStoreDetailsLinkClick,
    onGetDirectionsClick,
    onSetStoreClick,
    onScheduleAppointmentClick,
    onShopProductsClick,
    onShareClick,
    onContinueClick,
    productItem
  } = props;
  const showHeader = !hideHeader;

  return (
    <div className={`store-quick-view ${className}`} styleName="container">
      <div className="store-quick-view__top" styleName="top">
        {isMyStore && <MapMarkerAltSolidIcon styleName="my-store-badge" />}
        <div styleName="store-info">
          {showHeader && (
            <StoreQuickViewHeader
              isMyStore={isMyStore}
              myStoreTitle={myStoreTitle}
              showPitPassLogo={isPitPassStore && isMyStore && isNaN(distance)}
            />
          )}
          <StoreQuickViewDetails
            address={address}
            distance={distance}
            groupEntryStocks={groupEntryStocks}
            hasRatingLink={hasRatingLink}
            hidePhone={hidePhone}
            hideReadReviews={hideReadReviews}
            onCallStore={onCallStore}
            onReadReview={onReadReview}
            onReadReviewClick={onReadReviewClick}
            onStoreDetailsLinkClick={onStoreDetailsLinkClick}
            rating={rating}
            shouldSummarizePackage={shouldSummarizePackage}
            showDistance={showDistance}
            showPitPassLogo={isPitPassStore && (!isMyStore || !isNaN(distance))}
            storeCode={storeCode}
            storeDetailsLink={storeDetailsLink}
            storeHours={storeHours}
          />
        </div>
      </div>
      <StoreQuickViewFeatures features={features} />
      {groupEntryStocks?.length > 1 && (
        <ProductAvailabilityCollapsible groupEntryStocks={groupEntryStocks} />
      )}
      <StoreQuickViewCtas
        address={address}
        cartReconciliationWarningMessage={cartReconciliationWarningMessage}
        distance={distance}
        hideShorterWaitTimeMessage={hideShorterWaitTimeMessage}
        isMyStore={isMyStore}
        isStoreSelectionDisabled={isStoreSelectionDisabled}
        legacyStoreCode={legacyStoreCode}
        onChangeStore={onChangeStore}
        onContinueClick={onContinueClick}
        onGetDirectionsClick={onGetDirectionsClick}
        onScheduleAppointmentClick={onScheduleAppointmentClick}
        onSelectStore={onSelectStore}
        onSetStoreClick={onSetStoreClick}
        onShareClick={onShareClick}
        onShopProductsClick={onShopProductsClick}
        productItem={productItem}
        rating={rating}
        selectStoreButtonLabel={selectStoreButtonLabel}
        shouldSetStoreAndCart={shouldSetStoreAndCart}
        showAltChangeStoreCTA={showAltChangeStoreCTA}
        showAltDirectionsCTA={showAltDirectionsCTA}
        showAltScheduleAppointmentCTA={showAltScheduleAppointmentCTA}
        showAltShareStoreCTA={showAltShareStoreCTA}
        showAppointmentCTA={showAppointmentCTA}
        showContinueCTA={showContinueCTA}
        showMakeMyStoreCTA={showMakeMyStoreCTA}
        showShopProductsCTA={showShopProductsCTA}
        storeCode={storeCode}
      />
    </div>
  );
}

StoreQuickView.propTypes = {
  address: PropTypes.shape({
    line1: PropTypes.string,
    phone: PropTypes.string,
    postalCode: PropTypes.string,
    region: PropTypes.shape({
      isocodeShort: PropTypes.string
    }),
    town: PropTypes.string
  }),
  cartReconciliationWarningMessage: PropTypes.string,
  className: PropTypes.string,
  distance: PropTypes.number,
  features: PropTypes.array,
  groupEntryStocks: GroupEntryStocksProp,
  hasRatingLink: PropTypes.bool,
  hideHeader: PropTypes.bool,
  hidePhone: PropTypes.bool,
  hideReadReviews: PropTypes.bool,
  hideShorterWaitTimeMessage: PropTypes.bool,
  isMyStore: PropTypes.bool,
  isPitPassStore: PropTypes.bool,
  isStoreSelectionDisabled: PropTypes.bool,
  legacyStoreCode: PropTypes.string,
  myStoreTitle: PropTypes.string,
  onCallStore: PropTypes.func,
  onChangeStore: PropTypes.func,
  onContinueClick: PropTypes.func,
  onGetDirectionsClick: PropTypes.func,
  onReadReview: PropTypes.func,
  onReadReviewClick: PropTypes.func,
  onScheduleAppointmentClick: PropTypes.func,
  onSelectStore: PropTypes.func,
  onSetStoreClick: PropTypes.func,
  onShareClick: PropTypes.func,
  onShopProductsClick: PropTypes.func,
  onStoreDetailsLinkClick: PropTypes.func,
  productItem: PropTypes.object,
  rating: PropTypes.shape({
    numberOfReviews: PropTypes.number,
    rating: PropTypes.number
  }),
  selectStoreButtonLabel: PropTypes.string,
  shouldSetStoreAndCart: PropTypes.bool,
  shouldSummarizePackage: PropTypes.bool,
  showAltChangeStoreCTA: PropTypes.bool,
  showAltDirectionsCTA: PropTypes.bool,
  showAltScheduleAppointmentCTA: PropTypes.bool,
  showAltShareStoreCTA: PropTypes.bool,
  showAppointmentCTA: PropTypes.bool,
  showContinueCTA: PropTypes.bool,
  showDistance: PropTypes.bool,
  showMakeMyStoreCTA: PropTypes.bool,
  showShopProductsCTA: PropTypes.bool,
  showStoreAddressReviews: PropTypes.bool,
  storeCode: PropTypes.string,
  storeDetailsLink: PropTypes.string,
  storeHours: PropTypes.shape({
    monFriHours: PropTypes.string,
    satHours: PropTypes.string,
    sunHours: PropTypes.string
  })
};

const StoreQuickViewProps = PropTypes.shape(StoreQuickView.propTypes);

export { StoreQuickViewProps };

export default StoreQuickView;
