import React from 'react';

import './pitpass-logo.scss';

function PitpassLogo(): React.ReactElement {
  return (
    <img
      alt="Pit Pass"
      src="https://discounttire.scene7.com/is/content/discounttire/logos/DT_Pit-Pass_Logo-stand-alone.svg"
      styleName="logo"
    />
  );
}

export default PitpassLogo;
