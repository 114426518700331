import React from 'react';
import PropTypes from 'prop-types';

import Typography from '../../../../library/typography/typography';
import PitPassLogo from '../../../../components/pitpass-logo/pitpass-logo';

import './store-quick-view-header.scss';

function StoreQuickViewHeader(props) {
  const {
    isMyStore,
    myStoreTitle = 'My Selected Store',
    showPitPassLogo
  } = props;

  if (!isMyStore) {
    return null;
  }

  return (
    <div styleName="container">
      {isMyStore && (
        <Typography
          className="store-quick-view-header__title"
          styleName="title"
          tag="span"
          variant="H6"
          weight="black"
        >
          {myStoreTitle}
        </Typography>
      )}

      {showPitPassLogo && (
        <div styleName="pitpass-logo">
          <PitPassLogo />
        </div>
      )}
    </div>
  );
}

StoreQuickViewHeader.propTypes = {
  isMyStore: PropTypes.bool,
  myStoreTitle: PropTypes.string,
  showPitPassLogo: PropTypes.bool
};

export default StoreQuickViewHeader;
