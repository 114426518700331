import React from 'react';
import { faTimes } from '@fortawesome/pro-regular-svg-icons/faTimes';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

interface TimesRegularIconProps {
  className?: string;
  styleName?: string;
}

export default function TimesRegularIcon(props: TimesRegularIconProps) {
  return <FontAwesomeIcon icon={faTimes} {...props} />;
}
