import React, { memo } from 'react';

import Typography from '../../library/typography/typography';
import {
  CheckSolidIcon,
  PhoneSolidIcon,
  TimesSolidIcon
} from '../../shared/icons/icons';

import './product-availability-message.scss';

interface ProductAvailabilityMessageProps {
  availabilityMessage: string;
  availabilityMessageRank?: number;
  className?: string;
  hideIcons?: boolean;
  renderCheckIcon?: React.ReactElement;
  stockLevelCode?: string;
}

function ProductAvailabilityMessage(props: ProductAvailabilityMessageProps) {
  const {
    className,
    hideIcons = false,
    availabilityMessage,
    availabilityMessageRank,
    renderCheckIcon,
    stockLevelCode
  } = props;

  function getStockAvailabilityIcon() {
    const rank = availabilityMessageRank ?? 0;
    if (stockLevelCode === 'specialOrder') {
      return <PhoneSolidIcon styleName="phone-icon" />;
    } else if (rank > 5) {
      return <TimesSolidIcon styleName="x-icon" />;
    } else if (rank <= 5) {
      return renderCheckIcon ? (
        <>{renderCheckIcon}</>
      ) : (
        <CheckSolidIcon styleName="check-icon" />
      );
    }
    return (
      <div
        className="product-availability-message__icon-hidden"
        styleName="icon-hidden"
      />
    );
  }

  return (
    <Typography
      className={`${className || ''} product-availability-message`}
      styleName="container"
      tag="strong"
      weight="heavy"
    >
      {!hideIcons && getStockAvailabilityIcon()}
      <span>{availabilityMessage?.replace('at My Store', '')}</span>
    </Typography>
  );
}

export default memo(ProductAvailabilityMessage);
