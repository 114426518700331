import React from 'react';
import PropTypes from 'prop-types';

import './map-marker.scss';

function MapMarker(props) {
  const { className, number, onClick } = props;

  return (
    <div
      onClick={() => onClick(number)}
      onKeyPress={() => onClick(number)}
      role="button"
      styleName="container"
      tabIndex="0"
    >
      <svg
        className={className}
        height="32"
        styleName="marker"
        viewBox="0 0 100 100"
        width="32"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M53.2.05a32.82,32.82,0,0,0-32.8,32.8C20.4,51,53.2,100,53.2,100S86,51,86,32.85A33,33,0,0,0,53.2.05Zm0,54.15A21.8,21.8,0,1,1,75,32.4,21.8,21.8,0,0,1,53.2,54.2Z" />
        <circle cx="54" cy="32" r="22" styleName="fill" />
        <text styleName="number" x={number >= 10 ? 36 : 46} y="42">
          {number}
        </text>
      </svg>
    </div>
  );
}

MapMarker.propTypes = {
  className: PropTypes.string,
  number: PropTypes.number,
  onClick: PropTypes.func
};

export default MapMarker;
