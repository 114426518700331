import React, { useState } from 'react';
import PropTypes from 'prop-types';

import Typography from '../../library/typography/typography';
import TireRuggedSolidIcon from '../../shared/icons/tire-rugged-solid-icon';
import StoreQuickView, {
  StoreQuickViewProps
} from '../store-quick-view/store-quick-view';

import { getStoreDataLayerValues } from '../../shared/utils/store/store';

import { SERVICE_CODE } from '../../shared/constants/store';

import './store-quick-view-list.scss';

function StoreQuickViewList(props) {
  const {
    cartReconciliationWarningMessage,
    className,
    hideReadReviews = false,
    isCollapsible,
    isStoreSelectionDisabled,
    listNumberOffset,
    onCallStore,
    onReadReviewClick,
    onSelectStore,
    onStoreDetailsLinkClick,
    onGetDirectionsClick,
    onSetStoreClick,
    onScheduleAppointmentClick,
    onShopProductsClick,
    productItem,
    selectStoreButtonLabel,
    shouldSetStoreAndCart,
    shouldSummarizePackages,
    showAdditionalServicesBanner,
    showAppointmentCTA,
    showChooseThisStoreCTA,
    showPitPassBanner,
    storeRef,
    stores
  } = props;
  const [expandedIds, setExpandedIds] = useState([]);

  if (!Array.isArray(stores)) {
    return null;
  }

  function toggle(id) {
    let newExpandedIds = [];

    if (expandedIds.includes(id)) {
      newExpandedIds = expandedIds.filter(eId => eId !== id);
    } else {
      newExpandedIds = [...expandedIds, id];
    }

    setExpandedIds(newExpandedIds);
  }

  function filterCollapsedStoreProps(store) {
    return {
      address: store?.address,
      baseStore: store?.baseStore,
      distance: store?.distance,
      legacyStoreCode: store?.legacyStoreCode,
      rating: store?.rating,
      storeCode: store?.storeCode
    };
  }

  return (
    <ol className={className}>
      {stores.map((store, idx) => {
        const isExpanded = expandedIds.includes(store?.storeCode);
        let styleName = isCollapsible ? 'list-item-collapsible' : 'list-item';
        if (isExpanded) {
          styleName += ' list-item--open';
        }

        const storeDataLayerValues = getStoreDataLayerValues(store);

        return (
          <li
            key={`sqvl-${idx}`}
            ref={
              storeRef && storeRef.current
                ? el => (storeRef.current[idx] = el)
                : null
            }
            styleName={styleName}
          >
            {showAdditionalServicesBanner &&
              process.env.__FF_SHOW_ADDITIONAL_SERVICES_BANNER__ === 'true' &&
              store?.additionalServices.some(
                service => service.code === SERVICE_CODE.COMMERCIAL_VEHICLE
              ) && (
                <div styleName="services-message-container">
                  <TireRuggedSolidIcon styleName="icon" />
                  <span styleName="services-message">
                    This store services 19.5” tires
                  </span>
                </div>
              )}
            <div styleName="quick-view-container">
              <div styleName="list-index">
                <Typography size="small" styleName="list-number">
                  {(listNumberOffset || 0) + idx + 1}
                </Typography>
              </div>
              <StoreQuickView
                additionalServices={store?.additionalServices}
                cartReconciliationWarningMessage={
                  cartReconciliationWarningMessage
                }
                hidePhone={isCollapsible && !isExpanded}
                hideReadReviews={
                  hideReadReviews
                    ? hideReadReviews
                    : isCollapsible && !isExpanded
                }
                isPitPassStore={store?.pitPass}
                isStoreSelectionDisabled={isStoreSelectionDisabled}
                onCallStore={() => onCallStore?.(storeDataLayerValues)}
                onGetDirectionsClick={() =>
                  onGetDirectionsClick?.(storeDataLayerValues)
                }
                onReadReviewClick={() =>
                  onReadReviewClick?.(storeDataLayerValues)
                }
                onScheduleAppointmentClick={() =>
                  onScheduleAppointmentClick?.(storeDataLayerValues)
                }
                onSelectStore={onSelectStore}
                onSetStoreClick={onSetStoreClick}
                onShopProductsClick={() =>
                  onShopProductsClick?.(storeDataLayerValues)
                }
                onStoreDetailsLinkClick={() =>
                  onStoreDetailsLinkClick?.(storeDataLayerValues)
                }
                productItem={productItem}
                selectStoreButtonLabel={selectStoreButtonLabel}
                shouldSetStoreAndCart={shouldSetStoreAndCart}
                shouldSummarizePackage={shouldSummarizePackages}
                showAdditionalServicesBanner={
                  showAdditionalServicesBanner && store?.additionalServices
                }
                {...(!isCollapsible || isExpanded
                  ? store
                  : filterCollapsedStoreProps(store))}
                showAltScheduleAppointmentCTA={showAppointmentCTA}
                showChooseThisStoreCTA={showChooseThisStoreCTA}
                showPitPassBanner={showPitPassBanner}
              />
            </div>
            {isCollapsible && (
              <div styleName="list-actions">
                <button
                  onClick={() => toggle(store.storeCode)}
                  styleName={isExpanded ? 'toggle-expanded' : 'toggle'}
                  type="button"
                />
              </div>
            )}
          </li>
        );
      })}
    </ol>
  );
}

StoreQuickViewList.propTypes = {
  cartReconciliationWarningMessage: PropTypes.string,
  className: PropTypes.string,
  hideReadReviews: PropTypes.bool,
  isCollapsible: PropTypes.bool,
  isStoreSelectionDisabled: PropTypes.bool,
  listNumberOffset: PropTypes.number,
  onCallStore: PropTypes.func,
  onGetDirectionsClick: PropTypes.func,
  onReadReviewClick: PropTypes.func,
  onScheduleAppointmentClick: PropTypes.func,
  onSelectStore: PropTypes.func,
  onSetStoreClick: PropTypes.func,
  onShopProductsClick: PropTypes.func,
  onStoreDetailsLinkClick: PropTypes.func,
  productItem: PropTypes.object,
  selectStoreButtonLabel: PropTypes.string,
  shouldSetStoreAndCart: PropTypes.bool,
  shouldSummarizePackages: PropTypes.bool,
  showAdditionalServicesBanner: PropTypes.bool,
  showAppointmentCTA: PropTypes.bool,
  showChooseThisStoreCTA: PropTypes.bool,
  showMakeMyStoreCTA: PropTypes.bool,
  showPitPassBanner: PropTypes.bool,
  storeRef: PropTypes.shape({ current: PropTypes.object }),
  stores: PropTypes.arrayOf(StoreQuickViewProps)
};

export default StoreQuickViewList;
