import { SEARCH_RADIUS_OPTIONS } from '../../../shared/constants/search-types';

export function getRadiusExpansionInfo(radius, storesBySearchTermData) {
  const closestStoreInResults =
    storesBySearchTermData?.store?.search?.results?.[0];
  const distance = closestStoreInResults?.distance;
  let newRadius = radius;
  let msg = null;

  if (distance && distance > radius) {
    const nextGreatestRadius =
      SEARCH_RADIUS_OPTIONS.find(
        searchRadius => searchRadius.value > distance
      ) || SEARCH_RADIUS_OPTIONS[SEARCH_RADIUS_OPTIONS.length - 1];

    newRadius = nextGreatestRadius.value;
    msg = `We couldn't find any stores within ${radius} miles, so we're expanding your search.`;
  }

  return {
    msg,
    newRadius
  };
}
