import React from 'react';

import { StopwatchSolidIcon } from '../../shared/icons/icons';

import './shorter-wait-time-message.scss';

interface ShorterWaitTimeMessageProps {
  className?: string;
}

function ShorterWaitTimeMessage(props: ShorterWaitTimeMessageProps) {
  const { className = '' } = props;

  return (
    <aside
      className={`shorter-wait-time-message ${className}`}
      styleName="container"
    >
      <StopwatchSolidIcon styleName="icon" />
      <span styleName="text">
        30% shorter wait time on average when you buy and make an appointment
        online!
      </span>
    </aside>
  );
}

export default ShorterWaitTimeMessage;
