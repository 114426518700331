import React, { useMemo } from 'react';
import ReactSelect, { MenuPlacement } from 'react-select';

import SelectOptionWrapper from './components/select-option-wrapper/select-option-wrapper';
import SelectToggle from './components/select-toggle/select-toggle';
import SelectValueWrapper from './components/select-value-wrapper/select-value-wrapper';
import Input from '../input/input';
import { InputError } from '../input/input-types';

import generateUID from '../../shared/utils/generate-uid/generate-uid';

import styles from './select.scss';

interface SelectProps<Option> {
  'aria-labelledby'?: string;
  className?: string;
  error?: InputError;
  id?: string;
  infoMessage?: string;
  isDisabled?: boolean;
  isMenuOpenByDefault?: boolean;
  isRequired?: boolean;
  isSearchable?: boolean;
  label?: string;
  listboxId?: string;
  menuPlacement?: MenuPlacement;
  name?: string;
  onBlur?: React.FocusEventHandler<HTMLInputElement> | undefined;
  onChange?: (option: Option) => void;
  onFocus?: React.FocusEventHandler<HTMLInputElement> | undefined;
  options?: Option[];
  placeholder?: string;
  renderNoResultsFound?: (props: { inputValue: string }) => React.ReactNode;
  renderOption?: (props: Option) => React.ReactElement;
  renderValue?: (props: Option) => React.ReactElement;
  shouldAutofocus?: boolean;
  value?: Option;
}

function Select<Option>(props: SelectProps<Option>) {
  const {
    'aria-labelledby': ariaLabelledby,
    className,
    value,
    isDisabled = false,
    id,
    isRequired,
    isSearchable = false,
    isMenuOpenByDefault,
    label = '',
    name,
    placeholder: placeHolderProp = '',
    renderNoResultsFound: RenderNoResultsFound,
    renderOption: RenderOption,
    renderValue: RenderValue,
    shouldAutofocus,
    menuPlacement,
    options = [],
    onBlur,
    onChange,
    onFocus
  } = props;

  const placeholder = label ? undefined : placeHolderProp;
  const inputId = useMemo(() => id || generateUID(), [id]);
  const components = useMemo(() => {
    const customComponents = {
      DropdownIndicator: SelectToggle
    };

    if (RenderNoResultsFound) {
      customComponents['NoOptionsMessage'] = RenderNoResultsFound;
    }

    if (RenderOption) {
      customComponents['Option'] = props => (
        <SelectOptionWrapper {...props} component={RenderOption} />
      );
    }

    if (RenderValue) {
      customComponents['SingleValue'] = props => (
        <SelectValueWrapper {...props} component={RenderValue} />
      );
    }

    return customComponents;
  }, [RenderOption, RenderValue, RenderNoResultsFound]);

  function handleOnChange(option) {
    onChange?.(option as Option);
  }

  function noOptionsMessage() {
    return 'No results found';
  }

  return (
    <Input
      {...{
        ...props,
        isRequired: !placeholder && isRequired
      }}
      className={`${styles['container']} ${
        menuPlacement === 'top' ? styles['container--menu-top'] : ''
      } ${className}`}
      id={inputId}
      labelClassName={styles['label']}
    >
      <ReactSelect
        aria-labelledby={ariaLabelledby}
        autoFocus={shouldAutofocus}
        blurInputOnSelect={false}
        className="react-select"
        classNamePrefix="react-select"
        components={components}
        defaultMenuIsOpen={isMenuOpenByDefault}
        isDisabled={isDisabled}
        isSearchable={isSearchable}
        menuPlacement={menuPlacement}
        name={name}
        noOptionsMessage={noOptionsMessage}
        onBlur={onBlur}
        onChange={handleOnChange}
        onFocus={onFocus}
        options={options}
        placeholder={placeholder || ''}
        value={value}
      />
    </Input>
  );
}

export default Select;
