import React from 'react';

import './toggle.scss';

interface ToggleProps {
  ariaLabelledBy: string;
  isEnabled: boolean;
  onToggle: () => void;
}

function Toggle(props: ToggleProps): React.ReactElement {
  const { ariaLabelledBy, isEnabled, onToggle } = props;

  return (
    <label aria-labelledby={ariaLabelledBy} styleName="container">
      <input onChange={onToggle} styleName="input" type="checkbox" />
      <div styleName={`indicator ${isEnabled ? 'indicator--checked' : ''}`} />
    </label>
  );
}

export default Toggle;
