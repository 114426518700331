import { gql } from 'apollo-boost';

const storeSearchFields =
  process.env.__FF_SHOW_ADDITIONAL_SERVICES_BANNER__ === 'true'
    ? gql`
  fragment storeSearchFields on StoreSearchResult {
    pagination {
      currentPage
      numberOfPages
      totalNumberOfResults
    }
    results {
      address {
        country {
          isocode
          name
        }
        line1
        line2
        region {
          isocodeShort
          name
        }
        postalCode
        town
        phone
      }
      additionalServices {
        code
      }
      code
      baseStore
      distance
      description
      displayName
      geoPoint {
        latitude
        longitude
      }
      legacyStoreCode
      rating {
        cleanlinessRating
        knowledgeRating
        rating
        numberOfReviews
      }
      pitPass
    }
    sourceLatitude
    sourceLongitude
    hasPitPassStore
  }
`
    : gql`
  fragment storeSearchFields on StoreSearchResult {
    pagination {
      currentPage
      numberOfPages
      totalNumberOfResults
    }
    results {
      address {
        country {
          isocode
          name
        }
        line1
        line2
        region {
          isocodeShort
          name
        }
        postalCode
        town
        phone
      }
      code
      baseStore
      distance
      description
      displayName
      geoPoint {
        latitude
        longitude
      }
      legacyStoreCode
      rating {
        cleanlinessRating
        knowledgeRating
        rating
        numberOfReviews
      }
      pitPass
    }
    sourceLatitude
    sourceLongitude
    hasPitPassStore
  }
`;

export const GET_STORES_BY_SEARCH_TERM = gql`
  query searchStores(
    $includeInstallers: Boolean = false
    $page: Int = 0
    $radius: Float = 75
    $searchTerm: String = ""
    $additionalFilters: [AdditionalStoreFilter]
    $userStoreCode: String
  ) {
    store {
      search(
        includeInstallers: $includeInstallers
        page: $page
        radius: $radius
        searchTerm: $searchTerm
        additionalFilters: $additionalFilters
        userStoreCode: $userStoreCode
      ) {
        ...storeSearchFields
      }
    }
  }

  ${storeSearchFields}
`;

export const GET_STORES_BY_CITY_STATE = gql`
  query searchStoresByCity(
    $userStoreCode: String
    $page: Int = 0
    $city: String!
    $state: String!
    $includeInstallers: Boolean = false
  ) {
    store {
      searchByCity(
        userStoreCode: $userStoreCode
        page: $page
        city: $city
        state: $state
        includeInstallers: $includeInstallers
      ) {
        ...storeSearchFields
      }
    }
  }

  ${storeSearchFields}
`;

export const GET_STORES_BY_LAT_LONG = gql`
  query storesByLatLong(
    $includeInstallers: Boolean = false
    $latitude: Float!
    $longitude: Float!
    $page: Int = 0
    $radius: Float
    $additionalFilters: [AdditionalStoreFilter]
    $userStoreCode: String
  ) {
    store {
      listByLatLong(
        includeInstallers: $includeInstallers
        latitude: $latitude
        longitude: $longitude
        page: $page
        radius: $radius
        additionalFilters: $additionalFilters
        userStoreCode: $userStoreCode
      ) {
        ...storeSearchFields
      }
    }
  }

  ${storeSearchFields}
`;

export const GET_STATE_STORE_LIST = gql`
  query storeCountByLocation(
    $state: String
    $includeInstallers: Boolean = false
  ) {
    store {
      countByLocation(state: $state, includeInstallers: $includeInstallers) {
        name
        storeCount
      }
    }
  }
`;
