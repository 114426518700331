import React, { memo, useEffect, useState } from 'react';

import './image.scss';

interface ImageProps {
  alt: string;
  className?: string;
  disableDraggable?: boolean;
  height?: string;
  loading?: 'lazy' | 'eager';
  shouldFadeIn?: boolean;
  src: string;
  style?: object;
  title?: string;
  width?: string;
}

function Image(props: ImageProps) {
  const {
    alt,
    className,
    disableDraggable = false,
    height,
    loading,
    shouldFadeIn = true,
    src,
    style,
    title,
    width
  } = props;
  const [isReadyToLoad, setIsReadyToLoad] = useState(
    document.readyState === 'complete'
  );
  const [hasLoaded, setHasLoaded] = useState(false);
  let styleName = '';
  // Make sure images load after page load for perf
  const readySrc = isReadyToLoad
    ? src
    : 'data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7';

  if (shouldFadeIn) {
    styleName = hasLoaded ? 'img img--loaded' : 'img';
  }

  function setReadyToLoad() {
    window.removeEventListener('load', setReadyToLoad);
    setIsReadyToLoad(true);
  }

  useEffect(() => {
    if (document.readyState !== 'complete') {
      window.addEventListener('load', setReadyToLoad);
    }
  });

  return (
    <img
      alt={alt}
      aria-label={alt}
      className={className}
      draggable={disableDraggable ? 'false' : true}
      height={height}
      loading={loading}
      onLoad={() => setHasLoaded(true)}
      src={readySrc}
      style={style}
      styleName={styleName}
      title={title}
      width={width}
    />
  );
}

export default memo(Image);
