import { useCallback, useContext, useEffect, useRef } from 'react';

import { OptimisticDataContext } from '../../contexts';

import { actionTypes } from '../../constants/action-types';

function useOptimisticData() {
  const [optimisticData, dispatch] = useContext(OptimisticDataContext);
  const resetDataRef = useRef(null);

  const write = useCallback(
    payload => {
      if (payload && Object.keys(payload).length > 0) {
        dispatch({ payload, type: actionTypes.UPDATE });
      } else {
        console.error('You must pass an object with at least one key');
      }
    },
    [dispatch]
  );

  const read = useCallback(
    key => {
      if (!resetDataRef.current) {
        resetDataRef.current = {
          [key]: null
        };
      }

      return optimisticData[key] || null;
    },
    [optimisticData]
  );

  useEffect(
    () => () => {
      if (resetDataRef.current) {
        write(resetDataRef.current);
      }
    },
    [write]
  );

  return {
    read,
    write
  };
}

export default useOptimisticData;
