import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { hot } from 'react-hot-loader/root';

import Page, { Content, PageTitle } from '../../library/page/page';
import Breadcrumbs from '../../components/breadcrumbs/breadcrumbs';
import StoreLocator from '../../components/store-locator/store-locator';
import DefaultLayout from '../../layouts/default-layout/default-layout';

import { useDataLayer, useMyStore } from '../../shared/hooks/hooks';

import {
  PAGE_CATEGORY,
  PAGE_NAME,
  PAGE_SECTION_NAME,
  PAGE_SUBCATEGORY,
  PAGE_TYPE
} from '../../shared/constants/data-layer';

import './store-locator-page.scss';

function StoreLocatorPage() {
  const { myStore } = useMyStore();
  const { updateDataLayer } = useDataLayer();
  const documentTitle = `Tire service near me | Find a location | ${window.siteName}`;
  const documentDescription =
    'When you go looking for a "tire shop near me" we\'ve got you covered. With over 1000 locations, find your nearest store here.';
  const breadcrumbs = [
    {
      name: 'Home',
      url: '/'
    },
    {
      name: 'Store Locator'
    }
  ];

  useEffect(() => {
    updateDataLayer({
      [PAGE_CATEGORY]: 'store locator',
      [PAGE_NAME]: 'store locator search',
      [PAGE_SECTION_NAME]: documentTitle,
      [PAGE_SUBCATEGORY]: 'store locator search',
      [PAGE_TYPE]: 'store_locator'
    });
  }, [documentTitle, updateDataLayer]);

  return (
    <DefaultLayout hideMobileStoreHeader>
      <Page>
        {!window.isPreRendering && (
          <Helmet>
            <title>{documentTitle}</title>
            <link
              href={`${window.location.protocol}//${window.location.host}${window.location.pathname}`}
              rel="canonical"
            />
            <meta content={documentDescription} name="description" />
          </Helmet>
        )}
        {/*
         * Make sure to keep these files updated!
         * preloading the Google Maps JS files improves performance
         */}
        {window.isPreRendering && (
          <Helmet>
            <link
              as="script"
              href="https://maps.googleapis.com/maps-api-v3/api/js/45/1a/util.js"
              rel="preload"
            />
            <link
              as="script"
              href="https://maps.googleapis.com/maps-api-v3/api/js/45/1a/common.js"
              rel="preload"
            />
            <link
              as="script"
              href="https://maps.googleapis.com/maps-api-v3/api/js/45/1a/map.js"
              rel="preload"
            />
            <link
              as="script"
              href="https://maps.googleapis.com/maps-api-v3/api/js/45/1a/overlay.js"
              rel="preload"
            />
          </Helmet>
        )}
        <Breadcrumbs crumbs={breadcrumbs} />
        <PageTitle showDivider title="Store Locator" />
        <Content className="store-locator" showFrame={false}>
          <StoreLocator
            myStore={myStore}
            showAppointmentCTA
            showShopProductsCTA
          />
        </Content>
      </Page>
    </DefaultLayout>
  );
}

export default hot(StoreLocatorPage);
